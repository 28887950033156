import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUE = {
  weekdays: [
    $localize`Lu`,
    $localize`Ma`,
    $localize`Mi`,
    $localize`Ju`,
    $localize`Vi`,
    $localize`Sa`,
    $localize`Do`,
  ],
  months: [
    $localize`Enero`,
    $localize`Febrero`,
    $localize`Marzo`,
    $localize`Abril`,
    $localize`Mayo`,
    $localize`Junio`,
    $localize`Julio`,
    $localize`Agosto`,
    $localize`Septiembre`,
    $localize`Octubre`,
    $localize`Noviembre`,
    $localize`Diciembre`,
  ],
  weekLabel: $localize`sem`,
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor() {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUE.weekdays[weekday - 1];
  }

  override getWeekLabel(): string {
    return I18N_VALUE.weekLabel;
  }

  getMonthShortName(month: number): string {
    return I18N_VALUE.months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day} ${I18N_VALUE.months[date.month - 1]} ${date.year}`;
  }
}

import 'dayjs/locale/en';
import 'dayjs/locale/es';

import { Injectable } from '@angular/core';
import { getBrowserLanguage } from '../helpers/browser-lang.helper';
import dayjs from 'dayjs';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en-US';
export const AVAILABLE_LANGUAGES = ['es', 'en', 'to'];

@Injectable({ providedIn: 'root' })
export class LocaleService {
  readonly defaultLanguage = DEFAULT_LANGUAGE;
  readonly availableLanguages = AVAILABLE_LANGUAGES;

  getLanguage = (): string => getCurrentLanguage();
  getLocale = (): string => getCurrentLocale();
}

export function localeIdFactory(localeService: LocaleService): string {
  return localeService.getLanguage();
}

function fallbackLanguage(language?: string): string {
  switch (language) {
    case 'ca':
    case 'gl':
    case 'eu':
      return 'es';
    default:
      return DEFAULT_LANGUAGE;
  }
}

function getUrlLanguage(w: Window = window): string | undefined {
  const urlLang = w.location.pathname.split('/').filter(Boolean)[0];
  return AVAILABLE_LANGUAGES.includes(urlLang) ? urlLang : undefined;
}

export function getCurrentLanguage(w: Window = window): string {
  const potentialLanguage = getUrlLanguage(w) ?? getBrowserLanguage(w);
  return potentialLanguage && AVAILABLE_LANGUAGES.includes(potentialLanguage)
    ? potentialLanguage
    : fallbackLanguage(potentialLanguage);
}

export function getCurrentLocale(w: Window = window): string {
  switch (getCurrentLanguage(w)) {
    case 'es':
      return 'es-ES';
    default:
      return DEFAULT_LOCALE;
  }
}

export function loadDayjsLocale(language?: string): void {
  const lang = language ?? getCurrentLanguage();
  dayjs.locale(lang);
}

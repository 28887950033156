import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMAPortletModule } from '@emma-components/emma-portlet.module';
import { EMMAChartLayoutDirective } from '@emma-directives/emma-chart-layout.directive';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMAChartLayoutComponent } from './emma-chart-layout.component';
import { LayoutChartNumberComponent } from './layouts/chart-number.layout';
import { LayoutChartOnlyComponent } from './layouts/chart-only.layout';
import { LayoutChartTitleSidelabelsTotalComponent } from './layouts/chart-title-sidelabels-total.layout';
import { LayoutChartTitleTotalComponent } from './layouts/chart-title-total.layout';
import { LayoutPortletMinichartComponent } from './layouts/portlet-minichart.layout';
import { LayoutPortletTitleFooterlabelsFullheightComponent } from './layouts/portlet-title-footerlabels-fullheight.layout';
import { LayoutPortletTitleToplegendFullheightComponent } from './layouts/portlet-title-toplegend-fullheight.layout';
import { EMMAPipesModule } from '@emma-pipes/emma-pipes.module';

@NgModule({
  imports: [CommonModule, EMMAPortletModule, EMMALoadingModule, EMMADirectivesModule, EMMAPipesModule],
  exports: [EMMAChartLayoutComponent, EMMAChartLayoutDirective],
  declarations: [
    EMMAChartLayoutComponent,
    EMMAChartLayoutDirective,
    LayoutChartTitleSidelabelsTotalComponent,
    LayoutChartTitleTotalComponent,
    LayoutPortletTitleToplegendFullheightComponent,
    LayoutPortletTitleFooterlabelsFullheightComponent,
    LayoutChartOnlyComponent,
    LayoutChartNumberComponent,
    LayoutPortletMinichartComponent,
  ],
})
export class EMMAChartLayoutModule {}

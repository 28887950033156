import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { ERROR_CODES, isInEnum } from 'emma-common-ts';

const errorMessages = {
  [ERROR_CODES.OAUTH_USER_INVALID]: $localize`El usuario o clave no es correcto.`,
  [ERROR_CODES.ACCESS_BLOCKED_OR_NOT_PERMISSIONS]: $localize`El usuario no está asociado a una cuenta o su acceso ha sido bloqueado.`,
  [ERROR_CODES.TOKEN_NOT_EXIST]: $localize`No se ha realizado una conexión con Facebook. Contacta con soporte@emma.io.`,
  [ERROR_CODES.NATIVE_AD_TEMPLATE_DUPLICATED]: $localize`Ya existe una plantilla con el mismo templateId.`,
  [ERROR_CODES.NATIVE_AD_TEMPLATE_DEFAULT]: $localize`No se puede modificar la plantilla por defecto.`,
  [ERROR_CODES.SKUDO_PROVIDER_HAS_ACTIVE_URLS]: $localize`No se puede eliminar el proveedor. El proveedor tiene direcciones URL activas.`,
  [ERROR_CODES.SKUDO_AUTHORIZATION_CODE_INVALID]: $localize`El enlace no es válido. Por favor, solicita un nuevo enlace.`,
  [ERROR_CODES.SKUDO_AUTHORIZATION_CODE_EXPIRED]: $localize`El enlace ha cadudado. Por favor, solicita un nuevo enlace.`,
  [ERROR_CODES.FAILED_VERIFY_DOUBLE_CSRF]: $localize`Fallo al comprobar el CSRF token.`,
  [ERROR_CODES.FAILED_VERIFY_ID_TOKEN_GOOGLE]: $localize`Fallo al comprobar el Google ID.`,
  [ERROR_CODES.GOOGLE_PROFILE_INFO]: $localize`No se ha podido obtener toda la información necesaria del perfil de Google.`,
  [ERROR_CODES.OAUTH_ERROR]: $localize`Ha ocurrido un error con la autenticación.`,
  [ERROR_CODES.GOOGLE_ADS_CONNECTION_FAIL]: $localize`El token ha expirado o ya no tienes permisos sobre la cuenta. Vuelve a realizar la conexión con Google Ads.`,
  [ERROR_CODES.ACCOUNT_FEDERATED_INVALID_EMAIL]: $localize`Email no válido para esta cuenta federada de empresa.`,
  [ERROR_CODES.EMAIL_FEDERATED_INVALID_ACCOUNT]: $localize`Este dominio pertenece a una cuenta gestionada por otra empresa.`,
  [ERROR_CODES.EMAIL_FEDERATED_FORGOT_PASSWORD]: $localize`Este mail está gestionado por una empresa. Debes ponerte en contacto con tu gestor.`,
  [ERROR_CODES.MICROSOFT_PROFILE_INFO]: $localize`No se ha podido obtener toda la información necesaria del perfil de Microsoft.`,
  [ERROR_CODES.FAILED_VERIFY_AUTH_CODE_MICROSFT]: $localize`Error al comprobar el código de autorización de Microsoft.`,
  [ERROR_CODES.CURRENT_PASSWORD_INVALID]: $localize`La contraseña actual no es correcta.`,
  [ERROR_CODES.PASSWORD_ALREADY_USED_BEFORE]: $localize`La contraseña ya ha sido utilizada anteriormente. Por favor, elige una nueva contraseña.`,
  [ERROR_CODES.USER_IS_DELETED]: $localize`Esta cuenta ha sido eliminada. Póngase en contacto con el administrador de su cuenta para más información.`,
};

const warningMessages = {
  [ERROR_CODES.TOKEN_EXPIRED]: $localize`La conexión con Facebook ha caducado. Renuevala.`,
  [ERROR_CODES.AD_ACCOUNT_NOT_EXIST]: $localize`No se ha indicado la cuenta de anuncios de Facebook. Añadela en Captación > Fuente de medios > Facebook.`,
  [ERROR_CODES.USER_NOT_VERIFIED]: $localize`El usuario no ha sido verificado. Revisa tu correo para activar tu cuenta.`,
  [ERROR_CODES.USER_NOT_ACTIVATED]: $localize`El usuario no ha sido activado.`,
  [ERROR_CODES.USER_NOT_REGISTERED]: $localize`El usuario todavía no se ha registrado.`,
  [ERROR_CODES.GOOGLE_ADS_CONNECTION_FAIL]: $localize`El token ha expirado o ya no tienes permisos sobre la cuenta. Vuelve a realizar la conexión con Google Ads.`,
  [ERROR_CODES.NO_CSRF_TOKEN_BODY]: $localize`No se ha recibido el CSRF token.`,
  [ERROR_CODES.NO_CSRF_TOKEN_COOKIE]: $localize`No se ha recibido la cookie con el CSRF token.`,
  [ERROR_CODES.ACCESS_TOKEN_NOT_EXISTS]: $localize`La sesión ha caducado. Por favor, inicia sesión de nuevo.`,
  [ERROR_CODES.ACCESS_TOKEN_INVALID]: $localize`La sesión ha caducado. Por favor, inicia sesión de nuevo.`,
  [ERROR_CODES.ACCESS_TOKEN_EXPIRED]: $localize`La sesión ha caducado. Por favor, inicia sesión de nuevo.`,
  [ERROR_CODES.OAUTH_LOGIN_FAILS_EXCEED]: $localize`No puedes iniciar sesión debido a que la cuenta se ha desactivado por motivos de seguridad. Restablece tu contraseña para desbloquear tu cuenta.`,
  [ERROR_CODES.USER_REQUIRE_PASSWORD_RENEWAL]: $localize`Tu contraseña ha caducado. Puedes restablecerla pulsando en "¿Contraseña olvidada?".`,
  [ERROR_CODES.USER_DELETED_FIRST_FAIL]: $localize`Tu contraseña no es correcta. Haz click en Recuperar contraseña`,
};

const getApiErrorMessage = (errorCode: number): string => {
  if (isInEnum<ERROR_CODES>(ERROR_CODES, errorCode) && errorCode in errorMessages) {
    return errorMessages[errorCode];
  }
  return '';
};

const getApiWarningMessage = (errorCode: number): string => {
  if (isInEnum<ERROR_CODES>(ERROR_CODES, errorCode) && errorCode in warningMessages) {
    return warningMessages[errorCode];
  }
  return '';
};

export const showApiFeedbackMessage = (
  toastNotifications: ToastNotificationsService,
  errorCode: number
): void => {
  const errorMessage = getApiErrorMessage(errorCode);
  const warningMessage = getApiWarningMessage(errorCode);
  if (errorMessage) {
    toastNotifications.setError(errorMessage);
  } else if (warningMessage) {
    toastNotifications.setWarning(warningMessage);
  }
};

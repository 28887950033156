import { Component, OnInit } from '@angular/core';
import { AccountsService } from '@skudo/services/accounts.service';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { CurrentAccountService } from '@skudo/services/current-account.service';
import { AccountUser } from 'emma-common-ts/skudo';
import { Router } from '@angular/router';

@Component({
  selector: 'skudo-private-wrapper',
  templateUrl: './private-wrapper.component.html',
})
export class SkudoPrivateWrapperComponent extends OnUnsubscribe implements OnInit {
  currentAccount?: AccountUser;
  constructor(
    private readonly router: Router,
    private readonly currentAccountService: CurrentAccountService,
    private readonly accountsService: AccountsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentAccount = this.currentAccountService.$currentAccount.getValue();
    // Si en la URL viene el appId, cambia de app y mantiene el path
    const accountId = Number(this.router.routerState.snapshot.root.queryParams.accountId);
    if (accountId && !isNaN(accountId) && (!this.currentAccount || this.currentAccount?.id == accountId)) {
      this.accountsService.selectCurrentAccount(accountId);
    }
    this.currentAccount = this.currentAccountService.$currentAccount.getValue();
  }
}

import dayjs from 'dayjs';
import {
  CustomRelativeDateDefinitions,
  dayjsToNgbDate,
  RELATIVE_DATE,
  RELATIVE_RANGE,
} from './emma-date-picker.helpers';

export const getPredefinedDates = (): CustomRelativeDateDefinitions => {
  return {
    [RELATIVE_DATE.TODAY]: {
      label: $localize`Hoy`,
      from: dayjsToNgbDate(dayjs()),
      to: dayjsToNgbDate(dayjs()),
    },
    [RELATIVE_DATE.YESTERDAY]: {
      label: $localize`Ayer`,
      from: dayjsToNgbDate(dayjs().subtract(1, 'day')),
      to: dayjsToNgbDate(dayjs().subtract(1, 'day')),
    },
    [RELATIVE_DATE.TOMORROW]: {
      label: $localize`Mañana`,
      from: dayjsToNgbDate(dayjs().add(1, 'day')),
      to: dayjsToNgbDate(dayjs().add(1, 'day')),
    },
    [RELATIVE_DATE.IN_A_WEEK]: {
      label: $localize`En 7 días`,
      from: dayjsToNgbDate(dayjs().add(7, 'day')),
      to: dayjsToNgbDate(dayjs().add(7, 'day')),
    },
    [RELATIVE_DATE.IN_15_DAYS]: {
      label: $localize`En 15 días`,
      from: dayjsToNgbDate(dayjs().add(15, 'day')),
      to: dayjsToNgbDate(dayjs().add(15, 'day')),
    },
    [RELATIVE_DATE.IN_30_DAYS]: {
      label: $localize`En 30 días`,
      from: dayjsToNgbDate(dayjs().add(30, 'day')),
      to: dayjsToNgbDate(dayjs().add(30, 'day')),
    },
    [RELATIVE_DATE.END_OF_MONTH]: {
      label: $localize`Final de mes`,
      from: dayjsToNgbDate(dayjs().endOf('month')),
      to: dayjsToNgbDate(dayjs().endOf('month')),
    },
    [RELATIVE_DATE.END_OF_NEXT_MONTH]: {
      label: $localize`Final del próximo mes`,
      from: dayjsToNgbDate(dayjs().add(1, 'month').endOf('month')),
      to: dayjsToNgbDate(dayjs().add(1, 'month').endOf('month')),
    },
    [RELATIVE_DATE.START_OF_NEXT_MONTH]: {
      label: $localize`Comienzo del próximo mes`,
      from: dayjsToNgbDate(dayjs().add(1, 'month').startOf('month')),
      to: dayjsToNgbDate(dayjs().add(1, 'month').startOf('month')),
    },
  };
};

export const getPredefinedRanges = (): CustomRelativeDateDefinitions => {
  return {
    [RELATIVE_RANGE.LAST_7_DAYS]: {
      label: $localize`Últimos 7 días`,
      from: dayjsToNgbDate(dayjs().subtract(6, 'days')),
      to: dayjsToNgbDate(dayjs()),
    },
    [RELATIVE_RANGE.NEXT_7_DAYS]: {
      label: $localize`Próximos 7 días`,
      from: dayjsToNgbDate(dayjs().add(6, 'days')),
      to: dayjsToNgbDate(dayjs()),
    },
    [RELATIVE_RANGE.LAST_30_DAYS]: {
      label: $localize`Últimos 30 días`,
      from: dayjsToNgbDate(dayjs().subtract(29, 'days')),
      to: dayjsToNgbDate(dayjs()),
    },
    [RELATIVE_RANGE.NEXT_30_DAYS]: {
      label: $localize`Próximos 30 días`,
      from: dayjsToNgbDate(dayjs().add(29, 'days')),
      to: dayjsToNgbDate(dayjs()),
    },
    [RELATIVE_RANGE.CURRENT_MONTH]: {
      label: $localize`Este mes`,
      from: dayjsToNgbDate(dayjs().startOf('month')),
      to: dayjsToNgbDate(dayjs().endOf('month')),
    },
    [RELATIVE_RANGE.LAST_MONTH]: {
      label: $localize`Mes pasado`,
      from: dayjsToNgbDate(dayjs().subtract(1, 'month').startOf('month')),
      to: dayjsToNgbDate(dayjs().subtract(1, 'month').endOf('month')),
    },
    [RELATIVE_RANGE.NEXT_MONTH]: {
      label: $localize`Mes próximo`,
      from: dayjsToNgbDate(dayjs().add(1, 'month').startOf('month')),
      to: dayjsToNgbDate(dayjs().add(1, 'month').endOf('month')),
    },
  };
};

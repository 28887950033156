import { Component, Input } from '@angular/core';

export enum AUTH_TYPE {
  LOGIN = 'login',
  FORGOT_PASS = 'forgot_pass',
  RECOVER_PASS = 'recover_pass',
}

@Component({
  selector: 'skudo-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
})
export class SkudoAuthWrapperComponent {
  @Input() type!: AUTH_TYPE;

  AUTH_TYPE = AUTH_TYPE;
}

import { LOCAL_STORAGE_KEY, LocalStorageService } from '@skudo/services/local-storage.service';
import { AccountUser, User } from 'emma-common-ts/skudo';

export const getAuthKeys = (): {
  accessToken: string;
  apiKey: string;
  emmaApiKey?: string;
  emmaAccountKey?: string;
} | null => {
  const localStorageService = new LocalStorageService();
  const currentAccount = localStorageService.get<AccountUser>(LOCAL_STORAGE_KEY.CURRENT_ACCOUNT);
  const currentUser = localStorageService.get<User>(LOCAL_STORAGE_KEY.CURRENT_USER);
  const emmaApiKey = localStorageService.get<string | undefined>(LOCAL_STORAGE_KEY.EMMA_API_KEY);
  const emmaAccountKey = localStorageService.get<string | undefined>(LOCAL_STORAGE_KEY.EMMA_ACCOUNT_KEY);

  if (currentAccount && currentUser) {
    const accessToken = currentUser.access_token;
    const apiKey = currentAccount.apiKey;
    return {
      accessToken,
      apiKey,
      emmaApiKey,
      emmaAccountKey,
    };
  }

  return null;
};

export const updateDefaultAccount = (user: Partial<User>, apiKey?: string) => {
  user.accounts = user.accounts?.map((account) => ({
    ...account,
    isDefault: apiKey ? account.apiKey === apiKey : false,
  }));
  if (user.accounts?.length && !user.accounts.some((item) => item.isDefault)) {
    user.accounts[user.accounts.length - 1].isDefault = true;
  }
};

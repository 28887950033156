import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutingModule } from '@skudo/pages/auth/auth-routing.module';
import { PrivateRoutingModule } from '@skudo/pages/private/private-routing.module';
import { PublicRoutingModule } from '@skudo/pages/public/public-routing.module';

const routes: Routes = [{ path: '**', redirectTo: '/' }];

@NgModule({
  imports: [RouterModule.forRoot(routes), PublicRoutingModule, AuthRoutingModule, PrivateRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { PlatformErrorHandler } from '@platform/providers/error-handler.class';
import { API_URL, BASE_URL, TRACKER_URL } from '@platform/providers/url.provider';
import { environment } from '@skudo-environments/environment';
import { SkudoAuthHeaders } from '@skudo/helpers/auth-headers.class';
import { ENVIRONMENT } from 'emma-common-ts';

import { getCurrentLanguage } from '@platform/services/locale.service';
import { ApiAuthInterceptor } from '@skudo/interceptors/api-auth.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SkudoUiModule } from './components/skudo-ui.module';
import { ScriptInjectorsModule } from './script-injectors/script-injectors.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AmChartsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ScriptInjectorsModule,
    AppRoutingModule,
    SkudoUiModule,
    RecaptchaV3Module,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      disableTimeOut: false,
      preventDuplicates: true,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: PlatformErrorHandler },
    { provide: LOCALE_ID, useFactory: () => getCurrentLanguage() },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: BASE_URL, useValue: environment.baseUrl },
    { provide: TRACKER_URL, useValue: environment.trackerUrl },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: AuthHeaders, useClass: SkudoAuthHeaders },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { HttpHeaders } from '@angular/common/http';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { getAuthKeys } from './auth.helper';

export class SkudoAuthHeaders implements AuthHeaders {
  // eslint-disable-next-line class-methods-use-this
  get = () => {
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
    });
    // create authorization header with jwt token
    const authKeys = getAuthKeys();
    if (authKeys?.apiKey && authKeys?.accessToken) {
      headers = headers.set('apiKey', authKeys.apiKey).set('Authorization', `Bearer ${authKeys.accessToken}`);
    }
    return headers;
  };
}

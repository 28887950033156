import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@platform/services/api.service';
import { ApiChangeResponse, ApiResponse } from 'emma-common-ts';
import { SkudoUserScope, User, UserVerifyEmailResponse } from 'emma-common-ts/skudo';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { LOCAL_STORAGE_KEY, LocalStorageService } from './local-storage.service';
import { isEmpty } from 'lodash';
import { updateDefaultAccount } from '@skudo/helpers/auth.helper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
interface SignUpData {}

export const getDefaultUser = (): User => ({ email: '', access_token: '', refresh_token: '', accounts: [] });

@Injectable({ providedIn: 'root' })
export class UserService {
  $user: BehaviorSubject<User | undefined>;
  constructor(
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService
  ) {
    this.$user = new BehaviorSubject(this.localStorageService.get(LOCAL_STORAGE_KEY.CURRENT_USER));
  }

  private setCurrentUser(user: User): BehaviorSubject<User | undefined> {
    this.$user.next(user);
    return this.$user;
  }

  getCurrentUser(): BehaviorSubject<User | undefined> {
    return this.$user;
  }

  verify(user: Partial<User> | null | undefined): Observable<ApiResponse<SkudoUserScope>> {
    if (!user?.access_token) {
      return throwError(() => 'No user found');
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.access_token}`);
    return this.apiService.get<ApiResponse<SkudoUserScope>>(
      `/oauth2/scope`,
      {},
      {
        headers,
      }
    );
  }

  fetchUser(
    user: Pick<User, 'access_token'> | undefined = this.getCurrentUser().value,
    emmaAccountKey?: string,
    emmaApiKey?: string
  ): Observable<Partial<User> | undefined> {
    return this.verify(user).pipe(
      map((userScopeResponse) => {
        const { data } = userScopeResponse;
        if (!data) {
          return undefined;
        }
        const currentKeys = this.getEmmaKeys();
        const finalEmmaAccountKey = !isEmpty(emmaAccountKey) ? emmaAccountKey : currentKeys.emmaAccountKey;
        const finalEmmaApiKey = !isEmpty(emmaApiKey) ? emmaApiKey : currentKeys.emmaApiKey;
        updateDefaultAccount(data, finalEmmaAccountKey);
        const account = data.accounts.filter((item) => item.isDefault);

        if (account.length && account[0].apiKey) {
          // info para regresar a la misma cuenta de emma
          this.saveEmmaKeys(account[0].apiKey, finalEmmaApiKey);
        }

        const userData = {
          ...data.user,
          access_token: user?.access_token,
          accounts: data.accounts,
        };
        this.updateUser(userData).pipe(map(() => userData));
        return userData;
      })
    );
  }

  saveUser(user: User): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...user };
    this.localStorageService.set(LOCAL_STORAGE_KEY.CURRENT_USER, updated);
    return this.setCurrentUser(updated);
  }

  updateUser(user: Partial<User>): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...this.$user.value, ...user };
    return this.saveUser(updated);
  }

  saveEmmaKeys(emmaAccountKey?: string, emmaApiKey?: string): void {
    if (emmaAccountKey !== undefined) {
      this.localStorageService.set(LOCAL_STORAGE_KEY.EMMA_ACCOUNT_KEY, emmaAccountKey);
    }
    if (emmaApiKey !== undefined) {
      this.localStorageService.set(LOCAL_STORAGE_KEY.EMMA_API_KEY, emmaApiKey);
    }
  }

  getEmmaKeys(): { emmaAccountKey?: string; emmaApiKey?: string } {
    return {
      emmaAccountKey: this.localStorageService.get(LOCAL_STORAGE_KEY.EMMA_ACCOUNT_KEY),
      emmaApiKey: this.localStorageService.get(LOCAL_STORAGE_KEY.EMMA_API_KEY),
    };
  }

  removeEmmaKeys(): void {
    this.localStorageService.remove(LOCAL_STORAGE_KEY.EMMA_ACCOUNT_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_KEY.EMMA_API_KEY);
  }

  removeUser(): BehaviorSubject<User | undefined> {
    this.$user.next(undefined);
    this.localStorageService.remove(LOCAL_STORAGE_KEY.CURRENT_USER);
    return this.$user;
  }

  /**
   * Registra un nuevo usuario en la API
   */
  signUp(data: SignUpData): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/sign-up', data);
  }

  forgotPassword(email: string): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/forgot-password', { email });
  }

  changePassword(password: string, oldPassword: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/password', { password, oldPassword });
  }

  recoverPassword(password: string, authCode: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/recover-password', {
      password,
      token: authCode,
    });
  }

  activateAccount(authToken: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/activate', { token: authToken });
  }

  verifyIfUserEmailExists(email: string): Observable<ApiResponse<UserVerifyEmailResponse>> {
    return this.apiService.post<ApiResponse<UserVerifyEmailResponse>>('/user/verify-email', { email });
  }
}

import { HttpHeaders } from '@angular/common/http';
import { getCurrentLanguage } from '@platform/services/locale.service';
export class AuthHeaders {
  // eslint-disable-next-line class-methods-use-this
  get = () =>
    new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Accept-Language': getCurrentLanguage(),
    });
}

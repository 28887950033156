import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@skudo/services/user.service';
import { ROUTE, User } from 'emma-common-ts/skudo';
import { catchError, map, Observable, of } from 'rxjs';

export const autoLoginGuardFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const router = inject(Router);
  const userService = inject(UserService);

  const queryParams = new URLSearchParams(state.url.split('?')[1]);
  const emmaToken = queryParams.get('token') ?? undefined;
  const emmaAccountKey = queryParams.get('account') ?? undefined;
  const emmaApiKey = queryParams.get('app') ?? undefined;

  if (emmaToken && emmaAccountKey) {
    const user: Pick<User, 'access_token'> = { access_token: emmaToken };

    return userService.fetchUser(user, emmaAccountKey, emmaApiKey).pipe(
      map((result) => {
        if (result?.accounts) {
          router.navigateByUrl(ROUTE.MAIN);
          return false;
        }
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
  return of(true);
};

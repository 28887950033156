import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { AuthenticationService } from '@platform/services/authentication.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { ROUTE } from 'emma-common-ts/skudo';

import { getApiBaseUrl } from '@platform/helpers/base-url.helper';
import { showApiFeedbackMessage } from '@platform/i18n/api-errors.i18n';
import { API_URL } from '@platform/providers/url.provider';
import { getCurrentLocale } from '@platform/services/locale.service';
import { AUTH_TYPE } from '../auth-wrapper.component';
import { UserService } from '@skudo/services/user.service';

@Component({
  selector: 'skudo-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoLoginPageComponent implements OnInit, OnDestroy {
  loading = new Set<string>();
  type = AUTH_TYPE.LOGIN;

  @Input() returnUrl = '';

  @Input() email = '';
  @Input() remember = true;
  password = '';
  googleCallbackUrl = '';
  googleButtonLocale = '';
  emmaToken = '';
  emmaAccountKey = '';
  emmaApiKey = '';
  ROUTE = ROUTE;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    public notifications: ToastNotificationsService,
    @Inject(API_URL) apiUrl: string
  ) {
    const googleCallback = getApiBaseUrl(apiUrl);
    this.googleCallbackUrl = googleCallback.endsWith('/')
      ? `${googleCallback}oauth2/google/callback`
      : `${googleCallback}/oauth2/google/callback`;
    this.googleButtonLocale = getCurrentLocale();
  }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || ROUTE.MAIN;
    const errorCode = Number(this.activatedRoute.snapshot.queryParams['errorCode']);
    if (errorCode && !isNaN(errorCode)) {
      showApiFeedbackMessage(this.notifications, errorCode);
    }
    const signUpSuccess = this.activatedRoute.snapshot.queryParams['signUpSuccess'];
    if (signUpSuccess) {
      this.notifications.setSuccess($localize`¡Guay! Comprueba tu correo para confirmar tu cuenta.`);
    }
  }

  login(): void {
    this.loading.add('login');
    this.authService
      .login(this.email, this.password)
      .pipe(apiFeedback('login', this.notifications, this.loading))
      .subscribe((res) => {
        if (res?.access_token) {
          this.userService.updateUser({ ...res, email: this.email });
          this.router.navigateByUrl(this.returnUrl).catch(console.error);
        }
      });
  }

  isValid(): boolean {
    return Boolean(this.email && this.password);
  }

  onSubmit(ev: Event): void {
    ev.preventDefault();
    if (this.isValid()) {
      this.login();
    }
  }

  ngOnDestroy(): void {
    this.notifications.clear();
  }
}

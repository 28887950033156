import { CanActivateFn, UrlTree } from '@angular/router';
import { getCurrentLanguage } from '@platform/services/locale.service';
import { environment } from '@skudo-environments/environment';
import { getAuthKeys } from '@skudo/helpers/auth.helper';
import { Observable } from 'rxjs';

function getEmmaLoginUrl(): string | undefined {
  const authKeys = getAuthKeys();
  const currentUrl = window.location.href;
  const lng = getCurrentLanguage();

  return authKeys?.accessToken && authKeys?.apiKey
    ? `https://${environment.emmaDomain}/${lng}/login?fromUrl=${encodeURIComponent(currentUrl)}&token=${authKeys?.accessToken}&account=${authKeys?.apiKey}&app=${authKeys?.emmaApiKey ?? ''}`
    : undefined;
}

export const emmaLoginGuardFn: CanActivateFn = ():
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> => {
  const url = getEmmaLoginUrl();
  if (url) {
    window.location.href = url;
    return false;
  }
  return true;
};

<ul class="m-topbar__nav m-nav m-nav--inline">
    <li
        *ngIf="user"
        class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
        data-dropdown-toggle="hover"
    >
        <a href="javascript:;" class="m-nav__link m-dropdown__toggle">
            <span class="m-topbar__welcome" i18n>Hola,</span>
            <span class="m-topbar__username">
                <span id="EMMAusername">{{ user.firstName }}</span>
                <i class="la la-angle-down" aria-hidden="true"></i>
            </span>
        </a>
        <div class="m-dropdown__wrapper">
            <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
            <div class="m-dropdown__inner">
                <div
                    class="m-dropdown__header m--align-center"
                    style="
                        background: url(assets/media/app/img/misc/user_profile_bg.jpg);
                        background-size: cover;
                        background-position: center;
                    "
                >
                    <div class="m-card-user m-card-user--skin-dark">
                        <div class="m-card-user__details">
                            <span class="m-card-user__name m--font-weight-500">
                                {{ user.firstName }}
                                {{ user.lastName }}
                                <!-- ({{ currentApp.roleName }})-->
                            </span>
                            <span class="m-card-user__email m--font-weight-300">
                                {{ user.email }}
                            </span>
                            <span class="m-card-user__account m--font-weight-300">
                              {{ currentAccount.name }}
                          </span>
                        </div>
                    </div>
                </div>
                <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                        <ul class="m-nav m-nav--skin-light">
                            <li class="m-nav__section m--hide">
                                <span class="m-nav__section-text">Section</span>
                            </li>
                            <!--<li class="m-nav__item">
                                <a class="m-nav__link">
                                    <i class="m-nav__link-icon la la-money" aria-hidden="true"></i>
                                    <span class="m-nav__link-text" i18n>Preferencias cuenta</span>
                                </a>
                            </li>
                              li class="m-nav__item" *canView="PERMISSIONS.ROUTE_USER_ACCOUNT">
                                <a
                                    [canEdit]="PERMISSIONS.ROUTE_USER_ACCOUNT"
                                    [routerLink]="ROUTE.USER_ACCOUNT"
                                    class="m-nav__link"
                                >
                                    <i class="m-nav__link-icon la la-money" aria-hidden="true"></i>
                                    <span class="m-nav__link-text" i18n>Preferencias cuenta</span>
                                </a>
                            </li>
                            -->
                            <li class="m-nav__separator m-nav__separator--fit"></li>
                            <li class="m-nav__item">
                                <a
                                    class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                    [routerLink]="ROUTE.LOGOUT"
                                    i18n
                                >
                                    Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ul>

import { ActivatedRouteSnapshot } from '@angular/router';
import { AppEvent } from 'emma-common-ts/emma';

import { get, omit } from 'lodash';

import { BannerNotification, NotificationLevel } from '@platform/types/notification.model';
import { formatNumber } from './format.helper';

export enum TimeType {
  ForLife = 'Forlife',
  Hours = 'Hours',
  Days = 'Days',
}
export interface TypedTime {
  time: number;
  type: TimeType;
}

/**
 * From the entered hours, this method identifies the time format.
 * @param hours Time in hours.
 * @returns Array Returns time and type.
 */
export function treatHours(hours: number): TypedTime {
  if (hours === 0) {
    return { time: 0, type: TimeType.ForLife };
  }

  if (hours % 24 === 0) {
    return { time: hours / 24, type: TimeType.Days };
  }

  return { time: hours, type: TimeType.Hours };
}

export function formatChartJSTooltip(
  ttp: { [k: string]: number },
  data: { datasets: { data: number[]; xLabel: string; label: string }[] }
): string {
  const dataset = data.datasets[ttp.datasetIndex];
  const value = formatNumber(dataset.data[ttp.index]);
  let label = '';
  if (dataset.xLabel) {
    label = `${dataset.xLabel}: `;
  } else if (dataset.label) {
    label = `${dataset.label}: `;
  }
  return `${label}${value}`;
}

export function cleanShortLinkString(str: string): string {
  const opts = { noSpaces: true, onlyAscii: true, onlyUrl: true };
  let s = str;
  if (opts.onlyAscii) {
    // eslint-disable-next-line no-control-regex
    s = s.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '');
  }
  if (opts.onlyUrl) {
    s = s.replace(/[^a-zA-Z0-9_\-.]/g, '');
  }
  if (opts.noSpaces) {
    s = s.replace(/\s+/g, '');
  }
  return s;
}

export function getErrorText(error: unknown): string {
  if (!error) {
    return '';
  } else if ('string' === typeof error) {
    return error;
  } else if (Array.isArray(error)) {
    return error.map(getErrorText).join(' / ');
  } else if ('object' === typeof error) {
    if (get(error, 'message')) {
      return get(error, 'message');
    } else {
      return JSON.stringify(error);
    }
  } else if ('boolean' === typeof error) {
    return 'Error';
  } else if ('number' === typeof error) {
    return `Error ${error}`;
  }
  return '';
}

/**
 * Removes elements with empty strings in the provided property
 * @param items Array of items
 * @param key Property to clean and filter
 */
export function cleanMultiTypeEmpty(items: any[], key: string): any {
  return items
    .map((el) => ({
      ...el,
      [key]: 'string' === typeof el[key] ? el[key].trim() : el[key],
    }))
    .filter((el) => el[key] !== '' && el[key] !== null && el[key] !== undefined);
}

// Nasty business, Angular
export function getResolvedUrl(snapshot: ActivatedRouteSnapshot): string {
  const fullPath = getResolvedSegments(snapshot).join('/');
  return `/${fullPath}`;
}

export function getResolvedSegments(snapshot: ActivatedRouteSnapshot): string[] {
  return snapshot.children
    .map((route) => {
      const path: string[] = [];
      while (route.firstChild) {
        if (route.url[0]) {
          path.push(route.url[0].path);
        }
        route = route.firstChild;
      }
      if (route.url[0]) {
        path.push(route.url[0].path);
      }
      return path;
    })
    .filter((path) => Boolean(path))
    .flat();
}

export function smoothScrollTop(): void {
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(smoothScrollTop);
    window.scrollTo(0, currentScroll - currentScroll / 5);
  }
}

export const messageToNotification = (
  message: string,
  level: NotificationLevel = NotificationLevel.ERROR,
  dismissable = false
): BannerNotification => new BannerNotification({ key: message, message, level, dismissable });

export function haveArraysSameValues(...arrays: Array<Array<unknown>>): boolean {
  const baseLength = arrays[0]?.length || 0;
  if (arrays.some((arr) => arr.length !== baseLength)) {
    return false;
  }
  const joinSet = new Set(arrays.flat());
  for (const arr of arrays) {
    const arrSet = new Set(arr);
    if (joinSet.size !== arrSet.size) {
      return false;
    }
  }
  return true;
}

export function areArraysEquivalent(a: unknown[], b: unknown[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function arrayIncludes(haystack: unknown[], needles: unknown[]): boolean {
  for (const needle of needles) {
    if (!haystack.includes(needle)) {
      return false;
    }
  }
  return true;
}

export function getEventById(events: AppEvent[], id: number): AppEvent | undefined {
  return events.find((ev) => ev.id === id);
}
export function getEventByToken(events: AppEvent[], token: string): AppEvent | undefined {
  return events.find((ev) => ev.token === token);
}
export function getEventIdByToken(events: AppEvent[], token: string): number | null {
  const event = getEventByToken(events, token);
  if (event) {
    return event.id;
  }
  return null;
}

export const generateClassesArray = (...klasses: string[]): string[] => {
  const classes = new Set<string>();
  for (let klassString of klasses) {
    klassString = klassString.replace(/\s+/, ' ');
    for (const klass of klassString.split(' ')) {
      if (klass) {
        classes.add(klass.trim());
      }
    }
  }
  return Array.from(classes);
};

export const sortByProp =
  (prop: string | number | symbol, direction = 1) =>
  (elemA: unknown, elemB: unknown): number => {
    if (
      'object' === typeof elemA &&
      'object' === typeof elemB &&
      get(elemA, prop) !== undefined &&
      get(elemB, prop) !== undefined
    ) {
      if (get(elemA, prop) === get(elemB, prop)) {
        return 0;
      }
      return (get(elemA, prop) > get(elemB, prop) ? 1 : -1) * direction;
    }
    return 0;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
export function arrayToObject<T extends object>(...keys: Array<keyof T>) {
  return function (arr: Array<any>): T {
    return keys.reduce((acc, key, i) => {
      acc[key] = arr[i];
      return acc;
    }, {} as T);
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function mapArrayToObjects<T extends object>(...keys: Array<keyof T>) {
  return (arr: Array<any>) => arr.map(arrayToObject(...keys));
}

export function removeIndex(arr: Array<any>, index: number): Array<any> {
  return arr.filter((_, i) => i !== index);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function removeUndefinedProperties<T extends object, U = Partial<T>>(...keys: Array<keyof T>) {
  return (obj: T): U => {
    const omitKeys: Array<string> = [];
    for (const key of Object.keys(obj)) {
      if (!keys.length || (keys as Array<string>).includes(key)) {
        if (key in obj && obj[key] === undefined) {
          omitKeys.push(key);
        }
      }
    }
    return omit(obj, omitKeys) as U;
  };
}

export const cleanHtmlTags = (str: string): string => {
  const htmlTagRE = new RegExp('(<([^>]{1,2000})>)', 'g');
  // Replace all occurrences of the regular expression with an empty string.
  return str.replace(htmlTagRE, '');
};

export const downloadUrl = (url: string, filename?: string): void => {
  try {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    if (filename) {
      a.setAttribute('download', filename);
    }
    a.click();
    a.setAttribute('target', '_emma_download_url');
  } catch (ex) {
    window.open(url, '_blank');
  }
};

/**
 * Asigna a un objeto estas keys inicializadas a este valor.
 * @param keys Props
 * @param value Valor
 */
export const setKeysToValue = <T extends Record<string, unknown>>(
  keys: Array<keyof T>,
  value: unknown = 0,
  initialObject: T = {} as T
): T => keys.reduce((acc, kpi) => Object.assign(acc, { [kpi]: value }), initialObject);

/**
 * Divide un string date en formato Y-m-d H:m:s en dos strings
 * correspondientes al Y-m-d y H:m
 * @param date
 * @returns
 */
export const splitDate = (date: string): { day: string; time: string } => {
  const parts = date.replace('T', ' ').split(' ');
  const time = parts[1].split(':');
  return {
    day: parts[0],
    time: `${time[0]}:${time[1]}`,
  };
};

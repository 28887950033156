import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { getNotificationsPassword } from '@platform/helpers/auth.helper';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { UserService } from '@skudo/services/user.service';
import { MIN_PASSWORD_LENGTH, ROUTE } from 'emma-common-ts/skudo';
import { AUTH_TYPE } from '../auth-wrapper.component';

@Component({
  selector: 'skudo-recover-password-page',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoRecoverPasswordPageComponent implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  loading = new Set<string>();
  type = AUTH_TYPE.RECOVER_PASS;

  authCode = '';
  email = '';
  password = '';
  repeatPassword = '';
  isPasswordValid = false;

  constructor(
    public notifications: ToastNotificationsService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authCode = this.activatedRoute.snapshot.queryParams['authCode'];
    this.email = decodeURIComponent(this.activatedRoute.snapshot.queryParams['email']);
  }

  isValid() {
    return Boolean(this.form?.valid && this.isPasswordValid);
  }

  onSubmit(ev: Event) {
    ev.preventDefault();
    if (this.isValid()) {
      this.onSave();
    }
  }

  onSave() {
    this.loading.add('recover');
    this.userService
      .recoverPassword(this.password, this.authCode)
      .pipe(apiFeedback('recover', this.notifications, this.loading))
      .subscribe({
        next: () => {
          this.notifications.setSuccess('password', $localize`Contraseña cambiada correctamente.`);
          this.navigateToLogout();
        },
      });
  }

  showWarning() {
    const errors = getNotificationsPassword(MIN_PASSWORD_LENGTH, this.password, this.repeatPassword);
    const activeNotifications = this.notifications.getActives();
    activeNotifications.forEach((notification) => {
      if (!errors.includes(notification.message)) {
        this.notifications.remove(notification.toastId);
      }
    });
    this.isPasswordValid = errors.length === 0;
    if (!this.isPasswordValid) {
      errors.forEach((error) => {
        this.notifications.setError(error, undefined, {
          easeTime: 0,
        });
      });
    }
  }

  private navigateToLogout() {
    this.router.navigateByUrl(ROUTE.LOGOUT).catch((error) => console.error(error));
  }
}
